
import { Vue, Options } from 'vue-class-component';
import LoginLayout from '@/lib/layouts/LoginLayout.vue';
import { BaseButton, BaseTextInput } from '@/lib/components';
import { UserService } from '@/services/api';
import BaseAlert from '@/lib/components/Banner/BaseAlert.vue';
import { TokenService } from '@/services';

@Options({
  components: {
    BaseAlert,
    LoginLayout,
    BaseTextInput,
    BaseButton
  }
})
export default class ResetPasswordPage extends Vue {
  protected emailAddress = '';
  protected password = '';
  protected password_confirmation = '';
  protected step = 1;
  protected successMessage = '';
  protected errorMessage: { [key: string]: string[] } = {};
  protected loading = false;
  protected unableToReset = false;

  userService = new UserService();

  mounted() {
    TokenService.removeToken();

    if (this.$route.query.token) {
      this.step = 2;
    }
  }

  async requestResetPassword() {
    try {
      this.loading = true;
      await this.userService.requestResetPasswordEmail({
        email: this.emailAddress
      });
      this.successMessage = this.$t('platform.reset.send-success-message') as string;
      this.errorMessage = {};
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.errors) {
        this.errorMessage = error.response.data.errors;
      }
    } finally {
      this.loading = false;
    }
  }

  async setPassword() {
    try {
      this.loading = true;
      this.errorMessage = {};
      const token = this.getResetToken();
      const email = this.getEmail();

      await this.userService.setPassword({
        password: this.password,
        password_confirmation: this.password_confirmation,
        token,
        email
      });
      this.step = 3;
    } catch (error) {
      if (error.response.status === 400) {
        this.unableToReset = true;

        return;
      }

      if (error.response.status === 403) {
        this.errorMessage = { password: [error.response.data.message] };
        return;
      }
      this.errorMessage = error.response.data.errors;
    } finally {
      this.loading = false;
    }
  }

  getResetToken(): string {
    const token = this.$route.query.token;

    return typeof token === 'string' ? token : '';
  }

  getEmail(): string {
    const email = this.$route.query.email;

    return typeof email === 'string' ? email : '';
  }

  async backToLogin() {
    await this.$router.push({ name: 'login' });
  }
}
